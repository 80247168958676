<template>
  <div class="sa-table">
    <slot name="table" />
    <div>
      <slot name="pagination" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableSystemn'
}
</script>
<style lang="scss" scoped>
.sa-table {
  .el-table {
    border: 1px solid #d0d5dd;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: none;

    th.el-table__cell > .cell {
      padding-left: 12px;
      padding-right: 12px;
    }

    .cell:first-child {
      padding-left: 12px;
    }

    td.el-table__cell {
      border-bottom: 0.5px solid var(--neutral-200);
    }
  }
  .el-pagination {
    margin-top: unset;
    display: inline-flex;
    align-items: center;

    button {
      height: 32px !important;
    }

    .number,
    .more {
      border-radius: 4px !important;
      height: 32px;
      width: 36px;

      color: var(--neutral-600);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .el-select .el-input .el-input__inner {
      border-radius: 6px;
      border: 1px solid var(--neutral-300);
      background: var(--neutral-0);
      height: 32px;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--primary-600);
    border: 1px solid var(--primary-600);

    color: var(--neutral-0);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border-radius: 4px;
    border: 1px solid var(--neutral-300);
    background: var(--neutral-0);

    color: var(--neutral-600);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .custom-footer {
    border: 1px solid #d0d5dd;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    background: var(--Neutral-50, #F9FAFB);
    padding: 8px 12px;
  }

  .el-pagination__sizes {
    height: auto;
  }
}
</style>
