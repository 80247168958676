<template>
    <el-input
    :disabled="disabled"
    :min="min"
    :max="max"
    type="number"
    v-model="countNumber"
    @change="onChange"
    :placeholder="placeholder ? placeholder : $t('calendar.price_after_discount')">
        <!-- <i slot="suffix" class="el-input__icon">{{ suffix }}</i> -->
        <span v-if="suffix" slot="suffix" class="el-input__icon">{{ suffix }}</span>
    </el-input>
</template>
<script>
export default {
  name: 'InputNumber',
  props: {
    valueNumber: {
      type: Number,
      default: 0
    },
    dataObject: {},
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      // value: this.valueNumber ? this.valueNumber : this.min
    }
  },
  computed: {
    countNumber: {
      get () {
        // if(this.value) return this.value
        return this.valueNumber
      },
      set (val) {
        this.valueNumber = Number(val.split('.').join(''))
      }
    }
  },
  methods: {
    onChange (valueNumber) {
      this.valueNumber = valueNumber !== 'NaN' ? Number(valueNumber) : this.min

      if (this.valueNumber > this.max) {
        this.valueNumber = this.max
      } else if (this.valueNumber < this.min) {
        this.valueNumber = this.min
      }
      this.$emit('onChange', this.valueNumber, this.dataObject)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(input::-webkit-inner-spin-button) {
  display: none;
}
:deep(.el-input__inner) {
  text-align: center;
}
</style>
