<template>
  <tableSystem>
        <template slot="table">
          <el-table v-loading="ui.isLoading" :data="[]">
            <el-table-column label="econciliation" min-width="200">
              <template >
                <router-link
                  to="/"
                >
                  Link
                </router-link>

              </template>
            </el-table-column>

            <el-table-column
              label="notSentYet1"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            <el-table-column
              label="notSentYet2"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            <el-table-column
              label="notSentYet3"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            <el-table-column
              label="notSentYet4"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            <el-table-column
              label="notSentYet5"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            <el-table-column
              label="notSentYet6"
              min-width="150"
            >
              <template >
                123
              </template>
            </el-table-column>
            
          </el-table>
        </template>
        <template slot="pagination">
          <Pagination
            :paginationProps="pagination"
            :pageSizes="[10, 20, 30, 50]"
            @changePageSize="handleSizeChange"
            @changeCurrentPage="handleCurrentChange"
          />
        </template>
      </tableSystem>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import tableSystem from '@/components/tableSystem.vue'
export default {
  name: 'Table',
  components: {
    tableSystem,
    Pagination
  },
  data () {
    return {
      ui: {
        isLoading: false
      },
      filter: {
        limit: 20,
        page: 1
      },
      pagination: {
          total: 3,
          perPage: 20,
          currentPage: 1,
          lastPage: 1,
          from: 1,
          to: 1
      }
    }
  },
  methods: {
    handleSizeChange (value) {
      this.filter.limit = value
    },
    handleCurrentChange (value) {
      this.filter.page = value
    }
  }
}
</script>
<style lang="scss" scoped>
.sa-table {
  .el-table {
    border: 1px solid #d0d5dd;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: none;

    th.el-table__cell > .cell {
      padding-left: 12px;
      padding-right: 12px;
    }

    .cell:first-child {
      padding-left: 12px;
    }

    td.el-table__cell {
      border-bottom: 0.5px solid var(--neutral-200);
    }
  }
  .el-pagination {
    margin-top: unset;
    display: inline-flex;
    align-items: center;

    button {
      height: 32px !important;
    }

    .number,
    .more {
      border-radius: 4px !important;
      height: 32px;
      width: 36px;

      color: var(--neutral-600);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .el-select .el-input .el-input__inner {
      border-radius: 6px;
      border: 1px solid var(--neutral-300);
      background: var(--neutral-0);
      height: 32px;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--primary-600);
    border: 1px solid var(--primary-600);

    color: var(--neutral-0);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border-radius: 4px;
    border: 1px solid var(--neutral-300);
    background: var(--neutral-0);

    color: var(--neutral-600);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .custom-footer {
    border: 1px solid #d0d5dd;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    background: var(--Neutral-50, #F9FAFB);
    padding: 8px 12px;
  }

  .el-pagination__sizes {
    height: auto;
  }
}
</style>
