<template>
    <div class="flex-between custom-footer" >
        <p>
            <span class="body-small-regular neutral-600">total </span>
            <span class="body-small-semi-bold neutral-700">{{ pagination.total }}</span>
        </p>
        <div class="flex-center">
            <el-pagination
                background
                :page-size.sync ="pagination.perPage"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :layout="layout"
                :total="pagination.total">
            <span class="el-pagination__total body-small-regular neutral-600">display</span>
            <span class="el-pagination__sizes">
                <el-select
                    @change="handleSizeChange"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="page size">
                    <el-option
                        v-for="item in pageSizes"
                        :key="item"
                        :label="`${item}/page`"
                        :value="item">
                    </el-option>
                </el-select>
            </span>
            </el-pagination>
            <span class="el-pagination__jump body-small-regular neutral-600">{{ 'goTo' }} </span>
            <InputNumber
            class="el-pagination__editor is-in-pagination"
            :min="1"
            :max="pagination.lastPage"
            :placeholder="'Num'"
            :valueNumber="pagination.currentPage"
            @onChange="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
import InputNumber from '@/components/InputNumber.vue'

export default {
  name: 'Pagination',
  components: {
    InputNumber
  },
  props: {
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50]
    },
    layout: {
      type: String,
      default: 'slot, prev, pager, next'
    },
    paginationProps: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pagination: {
        total: 1,
        perPage: 1,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.dataPagination) {
      this.pagination = this.dataPagination
    }
  },
  computed: {
    dataPagination: function () {
      return JSON.parse(JSON.stringify(this.paginationProps))
    }
  },
  watch: {
    dataPagination: function () {
      this.pagination = this.dataPagination
    }
  },
  methods: {

    handleSizeChange (size) {
      this.pagination.currentPage = 1
      this.$emit('changePageSize', size)
    },
    handleCurrentChange (currentPage) {
      this.pagination.currentPage = currentPage
      this.$emit('changeCurrentPage', currentPage)
    }
  }
}
</script>
<style lang="scss" scoped>
  .flex-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .flex-center {
        display: flex;
        align-items: center;
        gap: 4px;
    }
</style>
